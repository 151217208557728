.App {
  text-align: center;
  font-family: Arial, sans-serif;
  background-color: #f5f5f5;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.App-header {
  width: 100%;
  background-color: #282c34;
  padding: 20px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-body {
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.App-link {
  display: flex;
  align-items: center;
  color: #61dafb;
  text-decoration: none;
  margin-top: 10px;
  padding: 10px 15px;
  background-color: #282c34;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.App-link:hover {
  background-color: #434c56;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
}

.github-logo {
  width: 24px;
  height: 24px;
  margin-right: 10px;
}

.Game-board {
  display: grid;
  grid-template-columns: repeat(3, 100px);
  grid-template-rows: repeat(3, 100px);
  gap: 0;
  margin-top: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.Game-square {
  width: 100px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  cursor: pointer;
  background-color: #fff;
  border: 3px solid #000000;
}

.Game-square:nth-child(3n + 1) {
  border-left: none;
}

.Game-square:nth-child(3n) {
  border-right: none;
}

.Game-square:nth-child(1),
.Game-square:nth-child(2),
.Game-square:nth-child(3) {
  border-top: none;
}

.Game-square:nth-child(7),
.Game-square:nth-child(8),
.Game-square:nth-child(9) {
  border-bottom: none;
}

.Game-square:hover {
  background-color: #eee;
}

.winner-message {
  margin-top: 20px;
  font-size: 24px;
}

.restart-button {
  background-color: #61dafb;
  border: none;
  color: white;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 20px 0;
  cursor: pointer;
  border-radius: 4px;
}

.restart-button:hover {
  background-color: #4db2e9;
}
